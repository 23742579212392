<template>
  <div id="tender-list">
    <v-card class="pb-8">
      <StandardCardHeader
        :title="'Daily income'"
      />
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                dense
                outlined
                :label="$t('From date')"
                class="w-full mb-1"
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              locale="srLatn"
              @input="dateMenu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                dense
                outlined
                :label="$t('To date')"
                class="w-full mb-1"
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              locale="srLatn"
              @input="dateMenu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="jobType"
            :items="jobTypes"
            :label="$t('Category')"
            dense
            outlined
            hide-details
            item-text="name"
            item-value="key"
            class="mb-1"
          >
          </v-select>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-btn
            color="primary"
            @click.prevent="getDataFromApi()"
          >
            {{ $t('Ok') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        cols="4"
        class="px-2 ma-0 pt-4 border-bottom-2px-silver"
      >
        <v-col><strong>{{ $t('Location') }}</strong></v-col>
        <v-col><strong>{{ $t('Payment') }}</strong></v-col>
        <v-col><strong>{{ $t('Completed warrants') }}</strong></v-col>
        <v-col><strong>{{ $t('Value') }}</strong></v-col>
        <v-col><strong>{{ $t('Value') }} sa PDV</strong></v-col>
      </v-row>

      <v-row
        v-for="(item, index) in items"
        :key="index"
        class="px-2 ma-0 pt-2 border-bottom-2px-silver"
      >
        <v-col
          @click="goToWarrants(item.payment_method, item.received_at_location)"
          class='cursor-pointer'
        >
          {{ getLocation(item.received_at_location) }}
        </v-col>
        <v-col
          @click="goToWarrants(item.payment_method, item.received_at_location)"
          class='cursor-pointer'
        >
          {{ item.payment_method ? $t(`${item.payment_method}`) : $t('Not specified') }}
        </v-col>
        <v-col
          @click="goToWarrants(item.payment_method, item.received_at_location)"
          class='cursor-pointer'
        >
          {{ item.number_of_warrants }}
        </v-col>
        <v-col
          @click="goToWarrants(item.payment_method, item.received_at_location)"
          class='cursor-pointer'
        >
          {{ (+item.total).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} RSD
        </v-col>
        <v-col
          @click="goToWarrants(item.payment_method, item.received_at_location)"
          class='cursor-pointer'
        >
          {{ (+item.total * 1.2).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} RSD
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const loading = true
    const options = {}
    const fromDate = ''
    const toDate = ''
    const dateMenu1 = false
    const dateMenu2 = false
    const items = []
    const jobType = ''
    const jobTypes = this.$store.state.warrantCategories.map(
      item => ({ key: item.key === 'not_selected' ? '' : item.key, name: this.$t(item.name) }),
    )

    return {
      options,
      loading,
      fromDate,
      toDate,
      dateMenu1,
      dateMenu2,
      jobTypes,
      jobType,
      items,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  methods: {
    goToWarrants($paymentMethod, $location) {
      const routeData = this.$router.resolve({
        name: 'admin-warrant-list',
        query: {
          payment_method: $paymentMethod,
          location: $location || "null",
          from_date: this.fromDate ?? '',
          to_date: this.toDate ?? '',
          job_type: this.jobType !== -1 ? this.jobType : '',
        },
      })
      window.open(routeData.href, '_blank');
    },
    getLocation(index) {
      const location = this.$store.getters.getStocks.find(stock => stock.id === +index)

      return location ? location.name : ''
    },
    getDataFromApi() {
      this.loading = true
      axiosIns.get(`/admin/reports/daily-sale?from_date=${this.fromDate ?? ''}&to_date=${this.toDate ?? ''}&job_type=${this.jobType !== -1 ? this.jobType : ''}`)
        .then(res => {
          this.items = res.data.daily_sale
          this.loading = false
        })
        .catch()
    },
  },
}
</script>
